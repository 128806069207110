import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Link from "next/link";
import cn from "classnames";

import styles from "./styles.module.scss";
import { PUBLIC_API_URL } from "../../../../config/constants";
import { LiveFeedPreloader } from "./LiveFeedPreloader";
import ArrowCircleRightIcon from "../../../common/icons/ArrowCircleRightIcon";

const LiveFeed = dynamic(
  () => import("../../../Home/Sections/HomeVideo/LiveFeedHls"),
  { ssr: false }
);

export function LiveFeedWidget({ widget: { title, data } }) {
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    let timerId = null;

    fetch(`${PUBLIC_API_URL}/live/feed`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("LiveFeed url invalid");
        }
        return res.json();
      })
      .then((data) => {
        setUrl(data.url);

        timerId = setTimeout(() => setShow(true), 3 * 1000);
      })
      .catch((err) => {
        setShow(false);
      });

    return () => clearTimeout(timerId);
  }, []);

  return (
    <>
      {title && <h3 className={styles.title}>{title}</h3>}
      <div className={styles.liveFeed}>
        <LiveFeed data={data} url={url} />
        <LiveFeedPreloader show={!show} />
      </div>
      <div className="text-center">
        <Link href="/show-schedule">
          <a
            href="/show-schedule"
            className={cn(
              "btn btn-danger w-100 p-1 d-flex justify-content-center align-center",
              styles.button
            )}
          >
            View Show Schedule&nbsp;
            <ArrowCircleRightIcon />
          </a>
        </Link>
      </div>
    </>
  );
}
