import { useCallback, useEffect, useState } from "react";

import { PUBLIC_API_URL } from "../config/constants";

const useTags = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = useCallback(() => {
    setIsFetching(true);
    fetch(`${PUBLIC_API_URL}/p1ml/tags`)
      .then((res) => res.json())
      .then((res) => {
        if (res.data && Array.isArray(res.data)) {
          setTags(res.data);
        }
      })
      .catch((err) => {
        setIsError(true);
        console.group("useTags.js:25 - fetchTags");
        console.warn(err);
        console.groupEnd();
      })
      .finally(() => setIsFetching(false));
  }, [setIsFetching, setTags, setIsError]);

  return {
    isFetching,
    isError,
    tags,
  };
};

export default useTags;
