import { AUTH_API_URL } from "../config/constants";
import { THROW_TOAST } from "../reducers/toasts";

export function createForm(div, forms) {
  const id = +div.dataset.formId;
  const formData = forms[id];

  formData && showForm(div, formData, id);
}

export function showForm(div, formData, id = +div.dataset.formId) {
  const form = document.createElement("form");
  div.replaceWith(form);
  if (div.id) {
    form.id = div.id;
  }

  const { schema, thankYouMessage, hideFormAfterSubmit } = formData;
  Formio.createForm(form, schema).then(async (form) => {
    form.on("submit", async (submitData) => {
      const { data } = submitData;
      delete data.submit;

      try {
        await fetch(`${AUTH_API_URL}/forms/${id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data }),
        });
        form.emit("submitDone", null);
      } catch (e) {
        dispatch({
          type: THROW_TOAST,
          data: { type: "error", message: e },
        });
      }
    });

    form.on("submitDone", () => {
      if (hideFormAfterSubmit) {
        form.element.childNodes[1].remove();
        form.element.style.height = "auto";
      }

      if (thankYouMessage) {
        form.element.childNodes[0].classList.add("formio_block");

        form.element.childNodes[0].innerHTML = thankYouMessage;
      }

      const submitButtons = form.element.querySelectorAll(
        'button[type="submit"]'
      );

      if (Array.isArray(submitButtons)) {
        submitButtons.forEach((btn) => (btn.disabled = true));
      }
    });
  });
}
