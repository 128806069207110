import Script from "next/script";
import React from "react";

export function GoogleAdWidget({ widget }) {
  if (!widget?.data) return null;

  return (
    <div className="d-flex gap-2 justify-content-around">
      {widget.data.content.map((slot, index) => (
        <div key={index}>
          <div dangerouslySetInnerHTML={{ __html: slot }} />
          <Script
            dangerouslySetInnerHTML={{
              __html: `(adsbygoogle = window.adsbygoogle || []).push({});`,
            }}
          />
        </div>
      ))}
    </div>
  );
}
