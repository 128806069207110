import { useEffect } from 'react';
import { FORMIO_ID } from '../config/constants';
import { showForm } from '../helpers/createForm';

export function useFormio(status, id, formData) {
  useEffect(() => {
    const href = 'https://cdn.form.io/formiojs/formio.full.min.css';
    const styleInner = `.alert-success, .has-success {display: none} 
                 .alert-success.formio_block{display:block}`;

    const isLoad = document.querySelector(`link[href="${href}"]`);
    if (isLoad) return;

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;

    const style = document.createElement('style');
    style.innerHTML = styleInner;

    document.head.append(link, style);
  }, []);

  useEffect(() => {
    if (status !== 'ready') return;

    if (!window.formio) {
      window.formio = new Formio(FORMIO_ID);
    }

    const div = document.getElementById(id)?.querySelector('div[data-formgenerator]');
    if (div) showForm(div, formData);
  }, [status]);
}
