export default function stripLink(link, embed) {
  if (typeof link === 'string') {
    link = link.replace('https://americasvoice.news', '');

    if(embed) {
      link = '/embed/news' + link
    }

    return link;
  } else {
    return '';
  }
}