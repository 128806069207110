import { useEffect, useRef, useState } from "react";

export function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "ready");
  const ref = useRef(null);

  useEffect(() => {
    if (!src) {
      setStatus("ready");
      return;
    }
    let script = document.querySelector(`script[src="${src}"]`);

    const setAttributeFromEvent = (event) => {
      script.setAttribute(
        "data-status",
        event.type === "load" ? "ready" : "error"
      );
    };

    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-status", "loading");

      document.body.appendChild(script);

      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else if (script && script !== null) {
      setStatus(script.getAttribute("data-status"));
    }

    ref.current = script;

    const setStateFromEvent = (event) => {
      setStatus(event.type === "load" ? "ready" : "error");
    };

    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);
    return () => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
        script.removeEventListener("load", setAttributeFromEvent);
        script.removeEventListener("error", setAttributeFromEvent);
      }
    };
  }, [src]);

  return { status, script: ref };
}
