import styles from "./styles.module.scss";
import React from "react";
import { responsiveImage } from "../../../../helpers/cdn";
import { PREVIEW_URL } from "config/constants";

export function LiveFeedPreloader({ show }) {
  if (!show) return null;

  return (
    <div className={styles.preloader}>
      <img
        width="100%"
        height="auto"
        alt="Live Feed"
        src={PREVIEW_URL}
        srcSet={`
          ${responsiveImage(PREVIEW_URL, 270)} 270w, 
          ${responsiveImage(PREVIEW_URL, 320)} 320w, 
          ${responsiveImage(PREVIEW_URL, 530)} 530w,
          ${responsiveImage(PREVIEW_URL, 740)} 740w, 
          ${responsiveImage(PREVIEW_URL, 940)} 940w
        `}
        sizes="((min-width: 200px) AND (max-width: 991px)) calc(100vw - 54px), 62vw"
      />
    </div>
  );
}
