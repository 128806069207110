import React, { useMemo } from "react";
import cn from "classnames";

import SingleTagBadge from "./SingleTagBadge";

import styles from "./styles.module.scss";

function TagBadges({
  tags = [],
  className = "",
  large = false,
  isCategory = false,
  isVideo = false,
  isSingleVideo = false,
}) {
  if (!tags || !tags.length) {
    return null;
  }

  const tagsBadges = useMemo(() => {
    return tags?.map((tag, idx) => (
      <SingleTagBadge
        key={tag?.id ?? idx}
        tag={tag}
        large={large}
        isCategory={isCategory}
        isMacroTag={tag?.is_macro === 1}
        isVideo={isVideo}
        isSingleVideo={isSingleVideo}
      />
    ));
  }, [tags, large, isCategory, isVideo, isSingleVideo]);

  return <ul className={cn(styles.root, className)}>{tagsBadges}</ul>;
}
export default TagBadges;
