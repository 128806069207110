import Link from "next/link";
import React from "react";
import cn from "classnames";

import styles from "./styles.module.scss";

function SingleTagBadge({
  tag = {},
  large = false,
  isCategory = false,
  isMacroTag = false,
  isVideo = false,
  isSingleVideo = false,
}) {
  if (isVideo) {
    return (
      <li className={cn(styles.tag, large ? styles.large : "")}>
        {!isSingleVideo && tag?.slug === "videos" ? null : (
          <Link href={`/category/${tag?.slug}`}>
            <a className={styles.link}>
              {isCategory && !isMacroTag ? tag?.name : tag?.title}
            </a>
          </Link>
        )}
      </li>
    );
  }

  return (
    <li className={cn(styles.tag, large ? styles.large : "")}>
      <Link
        href={
          isCategory || isMacroTag
            ? `/playlists/category/${tag?.slug}`
            : `/playlists/tag/${tag?.slug}`
        }
      >
        <a className={styles.link}>
          {isCategory && !isMacroTag ? tag?.name : tag?.title}
        </a>
      </Link>
    </li>
  );
}

export default SingleTagBadge;
