import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const isConditionCalculate = (innerWidth, devices) => {
  if (innerWidth < 576) {
    return devices?.mobile || false;
  } else if (innerWidth > 576 && innerWidth < 992) {
    return devices?.tablet || false;
  }
  return devices?.desktop || false;
};

export function useDevicesCondition(devices) {
  const innerWidth = useSelector((state) => state.devices.innerWidth);
  const [isCondition, setIsCondition] = useState(true);

  useEffect(() => {
    setIsCondition(isConditionCalculate(innerWidth, devices));
  }, [innerWidth]);

  return isCondition;
}
