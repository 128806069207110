import React from "react";
import cn from "classnames";
import styles from "./styles.module.scss";

export default function PlayButton({loading, active, big}) {
  return (
    <span
      className={cn(styles.root, loading && styles.loading, active && styles.active, big && styles.big)}
    />
  )
}
