import React, { useEffect } from "react";

export function GoogleManagerWidget({ widget }) {
  if (!widget?.data) return null;

  const defineSlots = widget.data;

  return (
    <div className="d-flex gap-2 justify-content-around">
      {defineSlots.map((slot) => {
        return <DefineSlotElement key={slot.divId} defineSlot={slot} />;
      })}
    </div>
  );
}

const DefineSlotElement = ({ defineSlot }) => {
  const { tag, divId, size } = defineSlot;

  useEffect(() => {
    if (window.googletag) {
      googletag.cmd.push(() => {
        if (!googletag.pubadsReady) {
          googletag.enableServices();
        }

        const slot = googletag.defineSlot(tag, size, divId);
        if (slot) {
          slot.addService(googletag.pubads());
          googletag.display(divId);
        }
      });
    }
  }, [tag, divId, size]);

  return <div id={divId} style={getMinimumSlotSize(size)} />;
};

function getMinimumSlotSize(size) {
  const maxValue = Number.MAX_VALUE;

  let minW = Number.MAX_VALUE;
  let minH = Number.MAX_VALUE;

  if (Array.isArray(size)) {
    // Convert googletag.SingleSize to googletag.MultiSize for convenience.
    const sizes = size.length <= 2 && !Array.isArray(size[0]) ? [size] : size;

    for (const size of sizes) {
      if (Array.isArray(size) && size[0] !== "fluid") {
        minW = Math.min(size[0], minW);
        minH = Math.min(size[1], minH);
      }
    }
  }

  return minW < maxValue && minH < maxValue
    ? // Static ad slot.
      { minWidth: `${minW}px`, minHeight: `${minH}px` }
    : // Fluid ad slot.
      { minWidth: "50%" };
}
