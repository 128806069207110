export function useImage(widget) {
  const href = widget.data.link || '#';
  const width = widget.data.width || 'auto';
  const height = widget.data.height || 'auto';
  const src = widget.data.url || '';
  const alt = widget.data.content || '';
  const target = widget.data.target || false;
  const fullWidth = widget.data.fullWidth || false;

  return { href, width, height, src, alt, target, fullWidth };
}
